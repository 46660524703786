import React from 'react';

function UserIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(props as any)}
    >
      <path
        d="M10.6666 4.41667C10.6666 5.38913 10.2803 6.32176 9.59265 7.00939C8.90501 7.69703 7.97238 8.08333 6.99992 8.08333C6.02746 8.08333 5.09483 7.69703 4.40719 7.00939C3.71956 6.32176 3.33325 5.38913 3.33325 4.41667C3.33325 3.44421 3.71956 2.51158 4.40719 1.82394C5.09483 1.13631 6.02746 0.75 6.99992 0.75C7.97238 0.75 8.90501 1.13631 9.59265 1.82394C10.2803 2.51158 10.6666 3.44421 10.6666 4.41667V4.41667ZM6.99992 10.8333C5.29811 10.8333 3.66601 11.5094 2.46265 12.7127C1.25929 13.9161 0.583252 15.5482 0.583252 17.25H13.4166C13.4166 15.5482 12.7405 13.9161 11.5372 12.7127C10.3338 11.5094 8.70173 10.8333 6.99992 10.8333V10.8333Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UserIcon;
