import React from 'react';

function OrdersIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.35 2.50004H13.8333C14.75 2.50004 15.5 3.25004 15.5 4.16671V17.5C15.5 18.4167 14.75 19.1667 13.8333 19.1667H2.16667C1.25 19.1667 0.5 18.4167 0.5 17.5V4.16671C0.5 3.25004 1.25 2.50004 2.16667 2.50004H5.65C6 1.53337 6.91667 0.833374 8 0.833374C9.08333 0.833374 10 1.53337 10.35 2.50004ZM2.16667 16.6667C2.16667 17.125 2.54167 17.5 3 17.5H13C13.4583 17.5 13.8333 17.125 13.8333 16.6667V5.00004C13.8333 4.54171 13.4583 4.16671 13 4.16671H12.1667V5.00004C12.1667 5.91671 11.4167 6.66671 10.5 6.66671H5.5C4.58333 6.66671 3.83333 5.91671 3.83333 5.00004V4.16671H3C2.54167 4.16671 2.16667 4.54171 2.16667 5.00004V16.6667ZM10.6667 11.6667H5.33333C5.33333 11.6667 4.66667 11.6667 4.66667 12.5C4.66667 13.3334 5.33333 13.3334 5.33333 13.3334H10.6667C10.6667 13.3334 11.3333 13.3334 11.3333 12.5C11.3333 11.6667 10.6667 11.6667 10.6667 11.6667ZM4.66667 8.33337H11.3333C11.3333 8.33337 12.1667 8.33337 12.1667 9.16671C12.1667 10 11.3333 10 11.3333 10H4.66667C4.66667 10 3.83333 10 3.83333 9.16671C3.83333 8.33337 4.66667 8.33337 4.66667 8.33337Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default OrdersIcon;
