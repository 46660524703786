import EmailIcon from '../components/icons/EmailIcon';
import PhoneIcon from '../components/icons/PhoneIcon';
import UserIcon from '../components/icons/UserIcon';
import PasswordEyeIcon from '../components/icons/PasswordEyeIcon';
import CloseIcon from '../components/icons/RoundedCloseIcon';

const getIcon = (icon: string, iconClassName?: string) => {
  switch (icon) {
    case 'email':
      return (
        <EmailIcon
          className={`h-5 w-5 text-font-black mb-1 ${iconClassName}`}
        />
      );
    case 'user':
      return <UserIcon className="h-5 w-5 text-font-black mb-1" />;
    case 'phone':
      return <PhoneIcon className="h-5 w-5 text-font-black mb-1" />;
    case 'close':
      return <CloseIcon className="h-3 w-2 text-font-black" />;
    case 'password':
      return <PasswordEyeIcon className={` ${iconClassName}`} />;
    default:
      break;
  }
};

export default getIcon;
