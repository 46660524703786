import React from 'react';
import Link from 'next/link';
import NProgress from 'nprogress';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { setUser } from '@sentry/nextjs';
import UserDropdown from './UserDropdown';
import NotificationDropdown from './NotificationDropdown';
import SidebarItem from './SidebarItem';
import { routes } from '../../routes';
import SignOutIcon from '../icons/SignOutIcon';
import { SIGN_OUT } from '../../graphql/mutations';
import { useUser, useNotify } from '../../hooks';
import ArrowIcon from '../icons/LeftArrow';

interface SidebarProps {
  isShown: boolean;
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function Sidebar({ isShown, setIsShown, setOpen }: SidebarProps) {
  const [collapseShow, setCollapseShow] = React.useState(false);
  const [signOut] = useMutation(SIGN_OUT);
  const notify = useNotify();
  const router = useRouter();
  const [user] = useUser();
  const [toggleOpen, setToggleOpen] = React.useState(false);
  const [windowInnerWidth, setWindowInnerWidth] = React.useState<number>(0);
  const onClickSignOut = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      NProgress.start();
      await signOut();
      setUser(null);
      localStorage.removeItem('user');
      await router.push('/sign-in');
    } catch (err) {
      notify(err.message, 'error');
    } finally {
      NProgress.done();
    }
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowInnerWidth(window?.innerWidth);
    }
  }, []);

  React.useEffect(() => {
    if (windowInnerWidth < 780 && windowInnerWidth > 0) {
      setIsShown(true);
    }
  }, [windowInnerWidth]);

  return (
    <nav
      className={` lg:left-0 lg:block lg:fixed lg:top-0 lg:bottom-0 lg:flex-row lg:flex-no-wrap lg:overflow-hidden shadow-xl  text-white flex flex-wrap items-center justify-between relative py-4 bg-secondary-500 z-100 transition-width duration-500  ease-in-out ${
        isShown ? 'lg:w-64' : 'lg:w-12'
      }`}
      onMouseEnter={(e) => {
        e.preventDefault();
        if (windowInnerWidth > 780) {
          setIsShown(true);
          setOpen(false);
        }
      }}
      onMouseLeave={(e) => {
        e.preventDefault();
        if (windowInnerWidth > 780) {
          setIsShown(false);
          setOpen(true);
        }
      }}
    >
      <div className="lg:flex-col lg:items-stretch lg:min-h-full lg:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto overflow-y-auto overflow-x-hidden">
        {/* Toggler */}
        <button
          className="cursor-pointer text-gray-600 ml-6 lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setCollapseShow(true);
          }}
        >
          {/* Burger Icon */}
          <svg
            fill="currentColor"
            className="w-6 h-6 fill-primary-100"
            viewBox="0 0 20 20"
          >
            <path
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
              fillRule="evenodd"
            />
          </svg>
        </button>
        {/* Brand */}
        <div
          className={`hidden lg:flex pt-3 items-center  ${
            isShown ? 'px-5' : 'px-2'
          }`}
        >
          {isShown ? (
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                router.push('/app');
              }}
            >
              <img
                src="/white_logo.png"
                alt="Logo"
                className={`${
                  windowInnerWidth < 780 ? 'w-1/6 h-4' : 'w-7/8 h-7 mx-auto'
                }`}
              />
            </button>
          ) : (
            <ArrowIcon
              className="w-3 h-3 ml-2  fill-transparent mt-2 mb-3 "
              onClick={(e) => {
                e.preventDefault();
                setIsShown(!isShown);
                setOpen(false);
              }}
              onMouseEnter={(e) => {
                e.preventDefault();
                setIsShown(!isShown);
                setOpen(false);
              }}
            />
          )}
        </div>
        {/* User */}
        {/* TODO ver para que es este user */}
        <ul className="lg:hidden items-center flex flex-wrap list-none px-6 ">
          <li className="hidden sm:inline-block relative">
            <NotificationDropdown />
          </li>
          <li className="inline-block relative px-6">
            <UserDropdown />
          </li>
        </ul>
        {/* Collapse */}
        <div
          className={`lg:flex lg:flex-col lg:items-stretch lg:opacity-100 lg:relative lg:mt-4 lg:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-hidden h-auto items-center flex-1  ${
            collapseShow ? 'bg-secondary-500 pt-4  w-full z-100' : 'hidden'
          }`}
        >
          {/* Collapse header */}
          <div className="lg:min-w-full lg:hidden block pb-4 border-b border-solid border-gray-300 px-6">
            <div className="flex flex-wrap justify-start">
              {/* Logo */}
              <Link href="/app/dashboard">
                <div className="w-1/2 cursor-pointer">
                  <img
                    src="/white_logo.png"
                    alt="Logo"
                    className=" w-3/4 h-6 sm:w-2/3 mt-2 sm:mt-0 sm:mx sm:h-full"
                  />
                </div>
              </Link>
              <div className="w-6/12 flex justify-end">
                <button
                  type="button"
                  className="cursor-pointer text-gray-700 lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                  onClick={(e) => {
                    e.preventDefault();
                    setCollapseShow(false);
                  }}
                >
                  {/* Close Icon */}
                  <svg
                    fill="currentColor"
                    className="w-6 h-6 fill-primary-100"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                      fillRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {/* Navigation */}
          <ul className="lg:flex-col lg:min-w-full flex flex-col list-none max-h-[720px] overflow-auto   ">
            {routes?.map((route) =>
              user?.permissions?.filter(
                (permission) =>
                  (permission?.key === route?.permission?.key &&
                    permission?.option === route?.permission?.option) ||
                  (permission?.key === 'superadmin' &&
                    route?.permission?.key !== 'enterpriseUser')
              )?.length > 0 ? (
                <SidebarItem
                  {...route}
                  key={route.text}
                  isShown={isShown}
                  toggleOpen={toggleOpen}
                  setToggleOpen={setToggleOpen}
                />
              ) : null
            )}
          </ul>
          {/* Logout */}
          <button
            className="text-primary-100 flex lg:absolute mt-6 p-4 lg:p-0 md:bottom-4 w-full justify-center items-center text-xs font-normal gap-2  border-purple-500"
            type="button"
            onClick={onClickSignOut}
          >
            <SignOutIcon className="w-4 h-4" />
            <span className={`my-auto ${isShown ? '' : 'hidden'}`}>
              Cerrar sesión
            </span>
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Sidebar;
