import React from 'react';

function EmailIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(props as any)}
    >
      <path
        d="M9 8.46327C8.63783 8.46327 8.28377 8.356 7.9825 8.155L0.75 3.33333L9 8.46327ZM9 8.46327C9.36217 8.46327 9.71623 8.356 10.0175 8.155L17.25 3.33333L9 8.46327ZM2.58333 13.4167H15.4167C15.9029 13.4167 16.3692 13.2235 16.713 12.8797C17.0568 12.5359 17.25 12.0696 17.25 11.5833V2.41667C17.25 1.93044 17.0568 1.46412 16.713 1.1203C16.3692 0.776486 15.9029 0.583332 15.4167 0.583332H2.58333C2.0971 0.583332 1.63079 0.776486 1.28697 1.1203C0.943154 1.46412 0.75 1.93044 0.75 2.41667V11.5833C0.75 12.0696 0.943154 12.5359 1.28697 12.8797C1.63079 13.2235 2.0971 13.4167 2.58333 13.4167Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EmailIcon;
