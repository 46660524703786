/* eslint-disable no-nested-ternary */
import React from 'react';
import { Combobox, Transition } from '@headlessui/react';
import CheckIcon from '../icons/CheckIcon';
import SelectorIcon from '../icons/AddIcon';
import LessIcon from '../icons/LessIcon';
import Button from './Button';
import { Input } from '../inputs';

export type IOption = {
  _id: string;
  name: string;
  disabled?: boolean;
};

interface MultipleSelectSearchProps {
  onChangeSelect?: (selectedoptions: any, name: string) => void;
  options: IOption[];
  defaultSelect?: IOption[] | [];
  label: string;
  resetSelection?: boolean;
  setResetSelection?: React.Dispatch<React.SetStateAction<boolean>>;
  setState?: React.Dispatch<React.SetStateAction<any>>;
  className?: string;
  actionName?: string;
  description: string;
  isReducer?: boolean;
}

export default function MultipleSelectSearch({
  label,
  onChangeSelect,
  options,
  defaultSelect = [],
  className = '',
  actionName,
  description,
  resetSelection = false,
  isReducer = true,
  setState,

  setResetSelection,
  ...props
}: MultipleSelectSearchProps) {
  const [optionsState, setOptionsState] = React.useState(options ?? []);
  const [search, setSearch] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState<IOption[]>([]);
  const [defaultOptions, setDefaultOptions] = React.useState<IOption[]>([]);
  const [isAutofocused, setIsAutofocused] = React.useState(false);

  function isSelectedOptions(value: IOption) {
    return selectedOptions.find((el) => el._id === value._id);
  }

  function handleSelect(value: IOption) {
    if (!isSelectedOptions(value)) {
      const selectedOptionsUpdated = [
        ...selectedOptions,
        optionsState.find((el) => el === value),
      ];
      setSelectedOptions(selectedOptionsUpdated as unknown as IOption[]);
    } else {
      handleDeselect(value);
    }
    setIsOpen(true);
  }

  function handleDeselect(value: IOption) {
    const selectedOptionsUpdated = selectedOptions.filter(
      (el) => el._id !== value._id
    );
    setSelectedOptions(selectedOptionsUpdated);
    setIsOpen(true);
  }

  React.useEffect(() => {
    if (
      defaultSelect?.length > 0 &&
      selectedOptions.length === 0 &&
      defaultOptions.length === 0
    ) {
      setSelectedOptions(defaultSelect);
      setDefaultOptions(defaultSelect);
    }
  }, [defaultSelect]);

  React.useEffect(() => {
    if (resetSelection && setResetSelection) {
      setSelectedOptions([]);
      setResetSelection(false);
    }
  }, [resetSelection]);

  React.useEffect(() => {
    if (isReducer) {
      onChangeSelect(selectedOptions, actionName);
    } else {
      setState(selectedOptions);
    }
  }, [selectedOptions]);

  React.useEffect(() => {
    if (!isOpen && search !== '') {
      setIsOpen(true);
    }
    const _options = options?.filter(
      (option) => option?.name?.toUpperCase().includes(search?.toUpperCase())
    );
    setOptionsState(_options);
  }, [search]);

  return (
    <div className=" z-50  items-center justify-center h-full flex w-full flex-row">
      <div className="w-full h-full flex flex-wrap">
        <Combobox
          className=" flex w-full flex-wrap justify-around items-center"
          as="div"
          value={selectedOptions}
          onChange={(e: any) => {
            handleSelect(e);
          }}
        >
          {() => (
            <div className="relative flex w-full flex-wrap items-center">
              <Combobox.Button className=" cursor-pointer relative w-full h-full rounded bg-white text-left transition ease-in-out duration-150 flex justify-around items-center ">
                {/* Widu */}
                <div
                  className={`mr-1 w-full h-fit flex flex-wrap rounded-md gap-1   ${
                    defaultSelect?.length === 0 ? ' cursor-default ' : ''
                  }`}
                  onMouseEnter={(e) => {
                    e.preventDefault();
                    // setIsOpen(true);
                    setIsAutofocused(true);
                  }}
                >
                  <Input
                    required
                    inputType="text"
                    placeholder={`Agregar ${label} existente`}
                    name="search"
                    type="text"
                    value={search}
                    autoComplete="off"
                    onChange={(e) => {
                      e.preventDefault();
                      setSearch(e.target.value);
                    }}
                    className=""
                    autoFocus={isAutofocused}
                  />
                </div>
              </Combobox.Button>
              {/* </span> */}

              <Transition
                unmount={false}
                show={isOpen}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="w-full"
              >
                <Combobox.Options
                  static
                  className="absolute w-full max-h-60 rounded py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none  sm:leading-5  mt-1 bg-white shadow-lg z-50"
                  onMouseLeave={(e) => {
                    e.preventDefault();
                    setIsOpen(false);
                  }}
                >
                  {optionsState.map((option) => {
                    const selected = isSelectedOptions(option);
                    return (
                      <Combobox.Option
                        key={option._id}
                        value={option}
                        disabled={option.disabled ?? false}
                        className={({ active }) =>
                          `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                            active
                              ? 'bg-[#3375B4] text-neutral-200'
                              : option.disabled
                              ? 'text-neutral-100 cursor-not-allowed'
                              : 'text-gray-900'
                          }`
                        }
                      >
                        {({ active }) => (
                          <>
                            <span
                              className={`block truncate font-light tracking-wider text-sm ${
                                selected ? 'font-medium' : ''
                              }`}
                            >
                              {option.name}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? 'text-white' : 'text-primary-400'
                                }`}
                              >
                                <CheckIcon
                                  className="h-4 w-4 text-green-300"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    );
                  })}
                </Combobox.Options>
              </Transition>
            </div>
          )}
        </Combobox>
      </div>
      {/* Add button */}
      {isOpen ? (
        <LessIcon
          className="z-100 h-4 w-4 ml-1 text-secondary-500 cursor-pointer"
          aria-hidden="true"
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(!isOpen);
            setSearch('');
          }}
        />
      ) : (
        <SelectorIcon
          className="z-100 h-4 w-4 ml-1 text-secondary-500 cursor-pointer"
          aria-hidden="true"
          onClick={() => setIsOpen(!isOpen)}
        />
      )}
    </div>
  );
}
