/* eslint-disable react/style-prop-object */
import React from 'react';

function Promotions({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.34199 1.14996L16.842 8.64996C17.142 8.94996 17.3337 9.37496 17.3337 9.83329C17.3337 10.2916 17.1503 10.7083 16.842 11.0083L11.0087 16.8416C10.7087 17.15 10.292 17.3333 9.83366 17.3333C9.37533 17.3333 8.95866 17.15 8.65866 16.85L1.15866 9.34996C0.850326 9.04163 0.666992 8.62496 0.666992 8.16663V2.33329C0.666992 1.41663 1.41699 0.666626 2.33366 0.666626H8.16699C8.62533 0.666626 9.04199 0.849959 9.34199 1.14996ZM2.33366 3.58329C2.33366 4.27496 2.89199 4.83329 3.58366 4.83329C4.27533 4.83329 4.83366 4.27496 4.83366 3.58329C4.83366 2.89163 4.27533 2.33329 3.58366 2.33329C2.89199 2.33329 2.33366 2.89163 2.33366 3.58329Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Promotions;
