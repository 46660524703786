import React from 'react';
import Modal from './Modal';
import Button from './Button';
import CloseIcon from '../icons/CloseIcon';
import Information from '../icons/InformationIcon';

interface WarningModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  text?: string;
}

function WarningModal({ isOpen, setOpen, text }: WarningModalProps) {
  return (
    <Modal isOpen={isOpen} setOpen={setOpen} title="">
      <div className="w-full flex justify-between mb-2">
        <Information className="w-6 h-6 text-secondary-500" />
        <button
          type="button"
          className="ml-auto flex h-6 w-6"
          onClick={(e) => {
            e.preventDefault();
            setOpen(false);
          }}
        >
          <CloseIcon className="w-3 h-3 m-auto text-neutral-500" />
          <span className="sr-only">Cerrar</span>
        </button>
      </div>
      <div className="w-full">
        <p className="text-neutral-500">{text}</p>
      </div>

      <div className="flex  gap-2 mt-6 w-full">
        <Button
          outline
          color="neutral"
          type="button"
          className="w-full "
          onClick={(e) => {
            e.preventDefault();
            setOpen(false);
          }}
        >
          Cancelar
        </Button>
      </div>
    </Modal>
  );
}

export default WarningModal;
