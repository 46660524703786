/* eslint-disable react/style-prop-object */
import React from 'react';

function Taxes({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.641341 4.01771L7.22467 0.551046C7.70801 0.292713 8.29134 0.292713 8.77467 0.551046L15.358 4.01771C15.6997 4.19271 15.9163 4.55105 15.9163 4.93438C15.9163 5.50938 15.4497 5.97605 14.883 5.97605H1.12467C0.549674 5.97605 0.0830078 5.50938 0.0830078 4.93438C0.0830078 4.55105 0.299674 4.19271 0.641341 4.01771ZM1.74967 8.89271V12.226C1.74967 12.9177 2.30801 13.476 2.99967 13.476C3.69134 13.476 4.24967 12.9177 4.24967 12.226V8.89271C4.24967 8.20105 3.69134 7.64271 2.99967 7.64271C2.30801 7.64271 1.74967 8.20105 1.74967 8.89271ZM6.74967 8.89271V12.226C6.74967 12.9177 7.30801 13.476 7.99967 13.476C8.69134 13.476 9.24967 12.9177 9.24967 12.226V8.89271C9.24967 8.20105 8.69134 7.64271 7.99967 7.64271C7.30801 7.64271 6.74967 8.20105 6.74967 8.89271ZM15.9163 16.3927C15.9163 17.0844 15.358 17.6427 14.6663 17.6427H1.33301C0.641341 17.6427 0.0830078 17.0844 0.0830078 16.3927C0.0830078 15.701 0.641341 15.1427 1.33301 15.1427H14.6663C15.358 15.1427 15.9163 15.701 15.9163 16.3927ZM11.7497 8.89271V12.226C11.7497 12.9177 12.308 13.476 12.9997 13.476C13.6913 13.476 14.2497 12.9177 14.2497 12.226V8.89271C14.2497 8.20105 13.6913 7.64271 12.9997 7.64271C12.308 7.64271 11.7497 8.20105 11.7497 8.89271Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Taxes;
