/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

export interface SelectProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  label?: string;
}

function _Select({
  label,
  className = '',
  children,
  name,
  onChange,
  ...rest
}: SelectProps) {
  return (
    <label className="block w-full" htmlFor={name}>
      <span className="text-neutral-900 font-normal text-sm">{label}</span>
      <select
        className={`block w-full text-neutral-700 font-normal text-sm rounded-md border-neutral-200 shadow-sm focus:border-0 focus:ring focus:ring-primary-100 focus:ring-opacity-50 ${className}`}
        name={name}
        onChange={onChange}
        {...rest}
      >
        {children}
      </select>
    </label>
  );
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => <_Select ref={ref} {...(props as any)} />
);
