import React from 'react';

function CheckIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM3.7 10.7L7.29 14.29C7.68 14.68 8.32 14.68 8.7 14.29L16.29 6.7C16.68 6.31 16.68 5.68 16.29 5.29C15.9 4.9 15.27 4.9 14.88 5.29L8 12.17L5.11 9.29C4.72 8.9 4.09 8.9 3.7 9.29C3.51275 9.47683 3.40751 9.73048 3.40751 9.995C3.40751 10.2595 3.51275 10.5132 3.7 10.7Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CheckIcon;
