import React from 'react';
import Link from 'next/link';
import UserDropdown from './UserDropdown';
import NotificationDropdown from './NotificationDropdown';
import { useUser, useCountry } from '../../hooks';
import getFlagIcon from '../../lib/getCountryFlag';

export default function Navbar() {
  const [user] = useUser();
  const [currentCountry] = useCountry();

  return (
    <nav className="absolute top-0 left-0 w-full z-10 bg-transparent lg:flex-row lg:flex-no-wrap lg:justify-start flex items-center p-4 text-gray-900">
      <div className="w-full mx-auto items-center flex flex-row justify-between lg:px-10 px-4">
        {/* <div className="w-full">{date.toLocaleString()}</div> */}
        <div className=" w-full " />
        <div className="gap-2 flex justify-center items-center">
          {getFlagIcon(currentCountry, 'h-10 w-12')}
          {user?.profilePicture ? (
            <Link href="/app/dashboard">
              <img
                src={user?.profilePicture}
                alt="pp"
                className=" h-7 w-7 sm:h-10 sm:w-10 items-center justify-center flex flex-row rounded-full text-white"
              />
            </Link>
          ) : (
            <button
              className="h-11 w-11 p-1 items-center justify-center flex flex-row rounded-full border-2 bg-secondary-500 text-white"
              type="button"
            >
              {`${user?.firstName?.charAt(0) ?? ''}${
                user?.lastName?.charAt(0) ?? ''
              } `}
            </button>
          )}
        </div>
        {/* TODO Ver si poner aqui el breadcrum */}

        <ul className="flex-col lg:flex-row list-none items-center hidden lg:flex lg:space-x-2">
          <NotificationDropdown />
          <UserDropdown />
        </ul>
      </div>
    </nav>
  );
}
