/* eslint-disable react/style-prop-object */
import React from 'react';

function Categories({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.87467 0.449988L3.77467 5.51665C3.43301 6.06665 3.83301 6.78332 4.48301 6.78332H10.6747C11.3247 6.78332 11.7247 6.06665 11.383 5.51665L8.29134 0.449988C7.96634 -0.0833455 7.19967 -0.0833455 6.87467 0.449988ZM12.1663 8.44999C10.8266 8.44999 9.58862 9.16473 8.91875 10.325C8.24887 11.4852 8.24887 12.9147 8.91875 14.075C9.58862 15.2352 10.8266 15.95 12.1663 15.95C13.5061 15.95 14.7441 15.2352 15.4139 14.075C16.0838 12.9147 16.0838 11.4852 15.4139 10.325C14.7441 9.16473 13.5061 8.44999 12.1663 8.44999ZM5.91634 15.5333H0.916341C0.458008 15.5333 0.0830078 15.1583 0.0830078 14.7V9.69999C0.0830078 9.24165 0.458008 8.86665 0.916341 8.86665H5.91634C6.37467 8.86665 6.74967 9.24165 6.74967 9.69999V14.7C6.74967 15.1583 6.37467 15.5333 5.91634 15.5333Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Categories;
