/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

export interface TextareaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label: string;
}
function _Textarea({ label, className = '', name, ...rest }: TextareaProps) {
  return (
    <label className="block w-full" htmlFor={name}>
      <span className="text-primary-500 font-normal text-sm">{label}</span>
      <textarea
        className={`text-sm rounded-md block w-full text-font-neutral-400  border border-neutral-200 font-light placeholder-neutral-400  outline-none focus:outline-none focus:shadow-none bg-transparent focus:ring-0 ${className}`}
        name={name}
        {...rest}
      />
    </label>
  );
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => <_Textarea ref={ref} {...(props as any)} />
);
