import React from 'react';

function PhoneIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(props as any)}
    >
      <path
        d="M0.75 2.58333C0.75 2.0971 0.943154 1.63079 1.28697 1.28697C1.63079 0.943154 2.0971 0.75 2.58333 0.75H5.59C5.78231 0.750146 5.96971 0.810772 6.12566 0.923296C6.28161 1.03582 6.39823 1.19454 6.459 1.377L7.83217 5.49558C7.90174 5.70489 7.89352 5.93227 7.80899 6.13601C7.72447 6.33974 7.56931 6.50617 7.372 6.60475L5.30308 7.64058C6.31721 9.88508 8.11492 11.6828 10.3594 12.6969L11.3953 10.628C11.4938 10.4307 11.6603 10.2755 11.864 10.191C12.0677 10.1065 12.2951 10.0983 12.5044 10.1678L16.623 11.541C16.8056 11.6018 16.9644 11.7186 17.077 11.8747C17.1895 12.0308 17.25 12.2184 17.25 12.4109V15.4167C17.25 15.9029 17.0568 16.3692 16.713 16.713C16.3692 17.0568 15.9029 17.25 15.4167 17.25H14.5C6.90633 17.25 0.75 11.0937 0.75 3.5V2.58333Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PhoneIcon;
