/* eslint-disable react/style-prop-object */
import React from 'react';

function Privileges({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.49167 3.64688L7.325 1.05522C7.75 0.863552 8.25 0.863552 8.675 1.05522L14.5083 3.64688C15.1083 3.91355 15.5 4.51355 15.5 5.17189V9.08855C15.5 13.7136 12.3 18.0385 8 19.0886C3.7 18.0385 0.5 13.7136 0.5 9.08855V5.17189C0.5 4.51355 0.891667 3.91355 1.49167 3.64688ZM3.58333 11.3386L5.74167 13.4969C6.06667 13.8219 6.6 13.8219 6.91667 13.4969L12.4083 8.00522C12.7333 7.68022 12.7333 7.15522 12.4083 6.83022C12.0833 6.50522 11.5583 6.50522 11.2333 6.83022L6.33333 11.7302L4.75833 10.1636C4.43333 9.83855 3.90833 9.83855 3.58333 10.1636C3.42729 10.3192 3.3396 10.5306 3.3396 10.7511C3.3396 10.9715 3.42729 11.1829 3.58333 11.3386Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Privileges;
