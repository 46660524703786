import React from 'react';

function RoundedCloseIcon({
  className,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        d="M8.17492 0.330838C8.06594 0.221608 7.91797 0.160222 7.76367 0.160222C7.60937 0.160222 7.46141 0.221608 7.35242 0.330838L4.49992 3.1775L1.64742 0.325005C1.53844 0.215774 1.39047 0.154388 1.23617 0.154388C1.08187 0.154388 0.933907 0.215774 0.824922 0.325005C0.597422 0.552505 0.597422 0.920005 0.824922 1.1475L3.67742 4L0.824922 6.8525C0.597422 7.08 0.597422 7.4475 0.824922 7.675C1.05242 7.9025 1.41992 7.9025 1.64742 7.675L4.49992 4.8225L7.35242 7.675C7.57992 7.9025 7.94742 7.9025 8.17492 7.675C8.40242 7.4475 8.40242 7.08 8.17492 6.8525L5.32242 4L8.17492 1.1475C8.39659 0.925838 8.39659 0.552505 8.17492 0.330838Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default RoundedCloseIcon;
