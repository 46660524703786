import React from 'react';
import Link from 'next/link';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';
import Table from '@avila-tek/ui/src/table';
import { User } from '../../models';

interface TableProps {
  tableData: any;
  columns: any;
  user?: User;
  createHref?: string;
  tableTitle?: string;
  createTitle?: string;
  permissionKey?: string;
  permissionOption?: string;
  contentHref?: string;
  contentPath?: string;
  hasCreate?: boolean;
  hasSearch?: boolean;
}

export default function GenericTable({
  tableData,
  user,
  createHref,
  tableTitle,
  createTitle,
  permissionKey,
  permissionOption,
  columns,
  contentHref,
  contentPath,
  hasCreate = false,
  hasSearch = true,
}: TableProps) {
  const router = useRouter();
  const hasAccess =
    user?.permissions?.filter(
      (permission) =>
        (permission?.key === permissionKey &&
          permission?.option === permissionOption) ||
        permission?.key === 'superadmin'
    )?.length > 0 ?? false;

  return (
    <Table<any> data={tableData} columns={columns} href={createHref}>
      <Table.Header className="rounded-t mb-0 px-4 py-3 border-0">
        <div className="relative w-full px-4 max-w-full flex-grow flex-1 flex overflow-hidden">
          <h3 className="font-semibold text-lg text-black mr-2">
            {tableTitle}
          </h3>
          {hasAccess && hasCreate ? (
            <Link href={createHref}>
              <p className="inline-flex my-auto bg-secondary-500 dark:bg-secondary-500 dark:border dark:border-primary-500 dark:hover:text-primary-500 hover:bg-secondary-500 text-slate-50 rounded px-2 py-0.5">
                <PlusIcon className="h-3 w-3 my-auto mr-2" />
                <span className="text-xs my-auto">{createTitle}</span>
              </p>
            </Link>
          ) : null}
        </div>
        {hasSearch ? (
          <div className="relative w-full px-2 max-w-full flex-1 self-end justify-end flex h-6 ">
            <Table.SearchInput className="max-w-xs text-sm block w-full text-font-neutral-400 font-light placeholder-neutral-400 outline-none bg-transparent outline outline-neutral-300 rounded-md outline-1 border-black" />
          </div>
        ) : null}
      </Table.Header>
      <div className="w-full border-none overflow-x-scroll md:overflow-hidden">
        <Table.Content<any>
          href={contentHref}
          path={contentPath}
          router={router}
          disableAccess={!hasAccess}
        />
        <Table.Footer className="rounded-b mb-0 px-4 py-3 border-0">
          <Table.Pagination className="ml-auto" />
        </Table.Footer>
      </div>
    </Table>
  );
}
