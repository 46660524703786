import WorldIcon from '../components/icons/WorldIcon';
import VenezuelaFlagIcon from '../components/icons/VenezuelaFlagIcon';
import ParaguayFlagIcon from '../components/icons/ParaguayFlag';
import ElSalvadorFlagIcon from '../components/icons/ElSalvadorFlag';
import BoliviaFlagIcon from '../components/icons/BoliviaFlag';

import { Country } from '../models';
import ColombianFlag from '../components/icons/ColombianFlag';
import HondurasFlag from '../components/icons/HondurasFlag';
import NicaraguaFlag from '../components/icons/NicaraguaFlag';
import MexicoFlagIcon from '../components/icons/MexicoFlag';
import GuatemalaFlagIcon from '../components/icons/GuatemalaFlag';

const getFlag = (country: Country, className) => {
  switch (country?.name) {
    case 'Todos':
      return <WorldIcon className={`${className}`} />;
    case 'Venezuela':
      return <VenezuelaFlagIcon className={` ${className}`} />;
    case 'Paraguay':
      return <ParaguayFlagIcon className={` ${className}`} />;
    case 'El Salvador':
      return <ElSalvadorFlagIcon className={` ${className}`} />;
    case 'Bolivia':
      return <BoliviaFlagIcon className={` ${className}`} />;
    case 'Colombia':
      return <ColombianFlag className={` ${className}`} />;
    case 'Honduras':
      return <HondurasFlag className={` ${className}`} />;
    case 'Nicaragua':
      return <NicaraguaFlag className={` ${className}`} />;
    case 'México':
      return <MexicoFlagIcon className={` ${className}`} />;
    case 'Guatemala':
      return <GuatemalaFlagIcon className={` ${className}`} />;

    default:
      return (
        <div
          className={`bg-neutral-200 rounded-full flex items-center justify-center ${className}`}
        >
          <p className="">{country?.abbreviation}</p>
        </div>
      );
  }
};

export default getFlag;
