import getIcon from './lib/getRouteIcon';

export interface IRoute {
  text: string;
  href: string;
  as?: string;
  privileges?: string[];
  key?: string;
  isCollapsible?: boolean;
  icon?: JSX.Element;
  children?: IRoute[];
  breadcrumb?: IBreadcrumb[];
}
export interface IBreadcrumb {
  name: string;
  href: string;
}

export const routes = [
  {
    text: 'Seleccionar país',
    href: '/app/select-country',
    as: '/app/select-country',
    isCollapsible: false,
    icon: getIcon('country', 'fill-primary-100 mr-2'),
    key: 'select-country',
    permission: { key: 'dashboard', option: 'read' },
  },
  {
    text: 'Dashboard',
    href: '/app/dashboard',
    as: '/app/dashboard',
    isCollapsible: false,
    icon: getIcon('dashboard', 'fill-primary-100 mr-2'),
    key: 'dashboard',
    permission: { key: 'dashboard', option: 'read' },
  },
  {
    text: 'Órdenes',
    href: '/app/orders',
    as: '/app/orders',
    isCollapsible: false,
    icon: getIcon('orders', 'fill-primary-100 mr-2'),
    key: 'orders',
    permission: { key: 'orders', option: 'read' },
  },
  {
    text: 'Clientes',
    href: '/app/clients',
    as: '/app/clients',
    isCollapsible: false,
    icon: getIcon('clients', 'fill-primary-100 mr-2'),
    key: 'clients',
    permission: { key: 'client', option: 'read' },
  },
  {
    text: 'Documentos',
    href: '/app/documents',
    as: '/app/documents',
    isCollapsible: false,
    icon: getIcon('documents', 'fill-primary-100 mr-2'),
    key: 'documents',
    permission: { key: 'document', option: 'read' },
  },
  {
    text: 'Cuestionarios',
    href: '/app/questionnaires',
    as: '/app/questionnaires',
    isCollapsible: false,
    icon: getIcon('questionnaire', 'fill-primary-100 mr-2'),
    key: 'questionnaires',
    permission: { key: 'questionnaire', option: 'read' },
  },
  {
    text: 'Firmas',
    href: '/app/firms',
    as: '/app/firms',
    isCollapsible: false,
    icon: getIcon('firm', 'fill-primary-100 mr-2'),
    key: 'firms',
    permission: { key: 'firm', option: 'read' },
  },
  {
    text: 'Empresas',
    href: '/app/enterprises',
    as: '/app/enterprises',
    isCollapsible: false,
    icon: getIcon('enterprises', 'fill-primary-100 mr-2'),
    key: 'enterprises',
    permission: { key: 'enterprise', option: 'read' },
  },
  {
    text: 'Resumen de empresas',
    href: '/app/enterprises-summary',
    as: '/app/enterprises-summary',
    isCollapsible: false,
    icon: getIcon('enterprises-summary', 'mr-2'),
    key: 'enterprises-summary',
    permission: { key: 'enterprise', option: 'read' },
  },
  {
    text: 'Corporativo',
    href: '/app/corporative-user',
    as: '/app/corporative-user',
    isCollapsible: false,
    icon: getIcon('enterprises', 'fill-primary-100 mr-2'),
    key: 'enterpriseUser',
    permission: { key: 'enterpriseUser', option: 'auth' },
  },
  {
    text: 'Documentos corporativos',
    href: '/app/corporative-documents',
    as: '/app/corporative-documents',
    isCollapsible: false,
    icon: getIcon('documents', 'fill-primary-100 mr-2'),
    key: 'enterpriseUser',
    permission: { key: 'enterpriseUser', option: 'auth' },
  },
  {
    text: 'Abogados',
    href: '/app/lawyers',
    as: '/app/lawyers',
    isCollapsible: false,
    icon: getIcon('lawyers', 'fill-primary-100 mr-2'),
    key: 'lawyers',
    permission: { key: 'lawyer', option: 'read' },
  },
  {
    text: 'Pagos',
    href: '/app/payments',
    as: '/app/payments',
    isCollapsible: false,
    icon: getIcon('payments', 'fill-primary-100 mr-2'),
    key: 'payments',
    permission: { key: 'payment', option: 'read' },
  },
  {
    text: 'Guías',
    href: '/app/guides',
    as: '/app/guides',
    key: 'guides',
    icon: getIcon('guides', 'fill-primary-100 mr-2 h-4 w-4'),
    isCollapsible: false,
    permission: { key: 'guide', option: 'read' },
  },
  {
    text: 'Roles y Privilegios',
    href: '/app/users',
    as: '/app/users',
    isCollapsible: false,
    icon: getIcon('privileges', 'fill-primary-100 mr-2'),
    key: 'users',
    permission: { key: 'user', option: 'read' },
  },
  {
    text: 'Mantenimiento',
    isCollapsible: true,
    icon: (
      <svg fill="currentColor" className="w-5 h-5 mr-2" viewBox="0 0 20 20">
        <path
          fillRule="evenodd"
          d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
          clipRule="evenodd"
        />
      </svg>
    ),
    permission: { key: 'client', option: 'read' },
    subLinks: [
      {
        text: 'Categorías',
        href: '/app/categories',
        as: '/app/categories',
        key: 'categories',
        icon: getIcon('categories', 'fill-primary-100 mr-2 h-3 w-3'),
      },
      {
        text: 'Promociones',
        href: '/app/promotions',
        as: '/app/promotions',
        key: 'promotions',
        icon: getIcon('promotions', 'fill-primary-100 mr-2 h-3 w-3'),
      },
      {
        text: 'Citas',
        href: '/app/appointments',
        as: '/app/appointments',
        key: 'appointments',
        icon: getIcon('appointments', 'fill-primary-100 mr-2 h-3 w-3'),
      },
    ],
  },
  {
    text: 'Configuración',
    isCollapsible: true,
    icon: (
      <svg fill="currentColor" className="w-5 h-5 mr-2" viewBox="0 0 20 20">
        <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
        <path
          fillRule="evenodd"
          d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
          clipRule="evenodd"
        />
      </svg>
    ),
    permission: { key: 'client', option: 'read' },
    subLinks: [
      {
        text: 'Países',
        href: '/app/countries',
        as: '/app/countries',
        key: 'countries',
        icon: getIcon('countries', 'fill-primary-100 mr-2 h-4 w-4'),
        permission: { key: 'country', option: 'read' },
      },
      {
        text: 'Monedas',
        href: '/app/currencies',
        as: '/app/currencies',
        key: 'currencies',
        icon: getIcon('currency', 'fill-primary-100 mr-2 h-4 w-4'),
        permission: { key: 'currency', option: 'read' },
      },
      {
        text: 'Métodos de Pago',
        href: '/app/payment-methods',
        as: '/app/payment-methods',
        key: 'payment-methods',
        icon: getIcon('paymentMethod', 'fill-primary-100 mr-2 h-4 w-4'),
        permission: { key: 'paymentMethod', option: 'read' },
      },
      // {
      //   text: 'Impuestos',
      //   href: '/app/taxes',
      //   as: '/app/taxes',
      //   key: 'taxes',
      //   icon: getIcon('taxes', 'fill-primary-100 mr-2 h-4 w-4'),
      //   permission: { key: 'tax', option: 'read' },
      // },
      {
        text: 'Variables',
        href: '/app/variables',
        as: '/app/variables',
        key: 'variables',
        icon: getIcon('variables', 'fill-primary-100 mr-2 h-4 w-4'),
        permission: { key: 'variable', option: 'read' },
      },
    ],
  },
];

export default routes;
