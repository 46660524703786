/* eslint-disable react/style-prop-object */
import React from 'react';

function PaymentIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5ZM9.66667 5.91667H12.1667C12.625 5.91667 13 5.54167 13 5.08333C13 4.625 12.625 4.25 12.1667 4.25H9.66667C9.20833 4.25 8.83333 4.625 8.83333 5.08333C8.83333 5.54167 9.20833 5.91667 9.66667 5.91667ZM9.66667 11.75H12.1667C12.625 11.75 13 11.375 13 10.9167C13 10.4583 12.625 10.0833 12.1667 10.0833H9.66667C9.20833 10.0833 8.83333 10.4583 8.83333 10.9167C8.83333 11.375 9.20833 11.75 9.66667 11.75ZM3.83333 7.16667H6.33333C6.79167 7.16667 7.16667 6.79167 7.16667 6.33333V3.83333C7.16667 3.375 6.79167 3 6.33333 3H3.83333C3.375 3 3 3.375 3 3.83333V6.33333C3 6.79167 3.375 7.16667 3.83333 7.16667ZM6.33333 3.83333H3.83333V6.33333H6.33333V3.83333ZM3.83333 13H6.33333C6.79167 13 7.16667 12.625 7.16667 12.1667V9.66667C7.16667 9.20833 6.79167 8.83333 6.33333 8.83333H3.83333C3.375 8.83333 3 9.20833 3 9.66667V12.1667C3 12.625 3.375 13 3.83333 13ZM6.33333 9.66667H3.83333V12.1667H6.33333V9.66667Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default PaymentIcon;
