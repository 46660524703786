/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import Link from 'next/link';
import { useUser } from '../../hooks';

interface SidebarItemProps {
  href?: string;
  as?: string;
  icon?: React.ReactChild;
  text: string;
  isCollapsible?: boolean;
  subLinks?: Array<{
    href?: string;
    as?: string;
    text?: string;
    icon?: any;
    test?: string;
    permission?: { key: string; option: string };
  }>;
  isShown: boolean;
  toggleOpen: boolean;
  setToggleOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SidebarItem({
  href,
  as,
  icon,
  text,
  isCollapsible = false,
  subLinks = [],
  isShown,
  toggleOpen,
  setToggleOpen,
}: SidebarItemProps) {
  const [user] = useUser();
  const [isOpen, setOpen] = React.useState(false);
  const toggle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setOpen(!isOpen);
  };

  if (!isCollapsible) {
    // Sidebar elements without children
    return (
      <li
        className={`items-center border-transparent hover:bg-secondary-400 border-r-4 hover:border-cool-gray-500 ${
          isShown ? 'px-6' : 'px-3'
        }`}
      >
        <Link href={href} as={as}>
          <p className="text-primary-100 w-auto text-xs py-3 flex flex-row overflow-hidden">
            {icon}{' '}
            <span className={`my-auto ${isShown ? '' : 'hidden'}`}>{text}</span>
          </p>
        </Link>
      </li>
    );
  }
  // Sidebar elements with children
  return (
    <li
      className={`items-center border-transparent hover:bg-secondary-400 border-r-4 hover:border-cool-gray-500 ${
        isShown ? 'px-6' : 'px-3'
      }`}
    >
      <button
        type="button"
        className="p-0 m-0 mb-1 bg-transparent focus:outline-none outline-none text-primary-100 text-xs py-3 flex flex-row  w-full"
        onClick={toggle}
      >
        {icon}
        <span className={`my-auto ${isShown ? '' : 'hidden'}`}>{text}</span>
        {!isShown ? null : (
          <>
            {isOpen ? (
              <svg
                fill="currentColor"
                className="ml-auto w-5 h-5 text-primary-100"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                fill="currentColor"
                className="ml-auto w-5 h-5 text-primary-100"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </>
        )}
      </button>
      <ul
        className={`${
          !isOpen ? 'hidden' : 'flex'
        } lg:flex-col lg:min-w-full flex flex-col list-none`}
      >
        {subLinks.map((link) =>
          user?.permissions?.filter(
            (permission) =>
              (permission?.key === link?.permission?.key &&
                permission?.option === link?.permission?.option) ||
              permission?.key === 'superadmin'
          )?.length > 0 ? (
            <li className="items-center" key={link.as}>
              <Link href={link.href} as={link.as}>
                <p
                  className={`text-primary-100 text-2xs py-3 flex flex-row flex-wrap w-full ${
                    !isShown ? 'ml-1' : 'ml-2'
                  }`}
                >
                  {link.icon}{' '}
                  <span className={`my-auto ${isShown ? '' : 'hidden'}`}>
                    {link.text}
                  </span>
                </p>
              </Link>
            </li>
          ) : null
        )}
      </ul>
    </li>
  );
}
