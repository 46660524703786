/* eslint-disable react/style-prop-object */
import React from 'react';

function PaymentMethod({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.667 0.333336H2.33366C1.40866 0.333336 0.675326 1.075 0.675326 2L0.666992 12C0.666992 12.925 1.40866 13.6667 2.33366 13.6667H15.667C16.592 13.6667 17.3337 12.925 17.3337 12V2C17.3337 1.075 16.592 0.333336 15.667 0.333336ZM14.8337 12H3.16699C2.70866 12 2.33366 11.625 2.33366 11.1667V7H15.667V11.1667C15.667 11.625 15.292 12 14.8337 12ZM2.33366 3.66667H15.667V2H2.33366V3.66667Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default PaymentMethod;
