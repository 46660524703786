import React from 'react';

function DocumentsIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6666 0.666687H5.66663C4.74996 0.666687 3.99996 1.41669 3.99996 2.33335V12.3334C3.99996 13.25 4.74996 14 5.66663 14H15.6666C16.5833 14 17.3333 13.25 17.3333 12.3334V2.33335C17.3333 1.41669 16.5833 0.666687 15.6666 0.666687ZM1.49996 4.00002C1.04163 4.00002 0.666626 4.37502 0.666626 4.83335V15.6667C0.666626 16.5834 1.41663 17.3334 2.33329 17.3334H13.1666C13.625 17.3334 14 16.9584 14 16.5C14 16.0417 13.625 15.6667 13.1666 15.6667H3.16663C2.70829 15.6667 2.33329 15.2917 2.33329 14.8334V4.83335C2.33329 4.37502 1.95829 4.00002 1.49996 4.00002ZM7.33329 8.16669H14C14.4583 8.16669 14.8333 7.79169 14.8333 7.33335C14.8333 6.87502 14.4583 6.50002 14 6.50002H7.33329C6.87496 6.50002 6.49996 6.87502 6.49996 7.33335C6.49996 7.79169 6.87496 8.16669 7.33329 8.16669ZM10.6666 11.5H7.33329C6.87496 11.5 6.49996 11.125 6.49996 10.6667C6.49996 10.2084 6.87496 9.83335 7.33329 9.83335H10.6666C11.125 9.83335 11.5 10.2084 11.5 10.6667C11.5 11.125 11.125 11.5 10.6666 11.5ZM7.33329 4.83335H14C14.4583 4.83335 14.8333 4.45835 14.8333 4.00002C14.8333 3.54169 14.4583 3.16669 14 3.16669H7.33329C6.87496 3.16669 6.49996 3.54169 6.49996 4.00002C6.49996 4.45835 6.87496 4.83335 7.33329 4.83335Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default DocumentsIcon;
