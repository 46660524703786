/* eslint-disable react/style-prop-object */
import React from 'react';

function Country({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.166992 6.72085C0.166992 3.49585 2.77533 0.887512 6.00033 0.887512C9.22533 0.887512 11.8337 3.49585 11.8337 6.72085C11.8337 10.1958 8.15033 14.9875 6.64199 16.8125C6.30866 17.2125 5.70033 17.2125 5.36699 16.8125C3.85033 14.9875 0.166992 10.1958 0.166992 6.72085ZM3.91699 6.72085C3.91699 7.87085 4.85033 8.80418 6.00032 8.80418C7.15032 8.80418 8.08366 7.87085 8.08366 6.72085C8.08366 5.57085 7.15032 4.63751 6.00032 4.63751C4.85033 4.63751 3.91699 5.57085 3.91699 6.72085Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Country;
