import React from 'react';

function HondurasFlagIcon({
  className,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <g clipPath="url(#clip0_4_7176)">
        <path
          d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
          fill="#F0F0F0"
        />
        <path
          d="M256 512C357.493 512 445.19 452.935 486.598 367.304H25.4023C66.8103 452.935 154.506 512 256 512Z"
          fill="#338AF3"
        />
        <path
          d="M256 0C154.506 0 66.8103 59.065 25.4023 144.696H486.597C445.19 59.065 357.493 0 256 0Z"
          fill="#338AF3"
        />
        <path
          d="M157.546 166.957L165.834 192.466H192.655L170.957 208.23L179.246 233.739L157.546 217.974L135.846 233.739L144.135 208.23L122.436 192.466H149.257L157.546 166.957Z"
          fill="#338AF3"
        />
        <path
          d="M157.546 278.261L165.834 303.771H192.655L170.957 319.535L179.246 345.043L157.546 329.278L135.846 345.043L144.135 319.535L122.436 303.771H149.257L157.546 278.261Z"
          fill="#338AF3"
        />
        <path
          d="M354.456 166.957L362.745 192.466H389.566L367.867 208.23L376.156 233.739L354.456 217.974L332.756 233.739L341.045 208.23L319.346 192.466H346.168L354.456 166.957Z"
          fill="#338AF3"
        />
        <path
          d="M354.456 278.261L362.745 303.771H389.566L367.867 319.535L376.156 345.043L354.456 329.278L332.756 345.043L341.045 319.535L319.346 303.771H346.168L354.456 278.261Z"
          fill="#338AF3"
        />
        <path
          d="M256.001 222.609L264.29 248.119H291.111L269.412 263.883L277.701 289.391L256.001 273.626L234.301 289.391L242.59 263.883L220.891 248.119H247.712L256.001 222.609Z"
          fill="#338AF3"
        />
      </g>
      <defs>
        <clipPath id="clip0_4_7176">
          <rect width="512" height="512" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HondurasFlagIcon;
