import { gql } from '@apollo/client';
import {
  USER_FRAGMENT,
  CURRENCY_FRAGMENT,
  CLIENT_FRAGMENT,
  DOCUMENT_FRAGMENT,
  QUESTIONNAIRE_FRAGMENT,
  VARIABLE_FRAGMENT,
  LAWYER_FRAGMENT,
  PAYMENT_METHOD_FRAGMENT,
  FIRM_FRAGMENT,
  PROMOTION_FRAGMENT,
  GUIDE_FRAGMENT,
  PAYMENT_FRAGMENT,
  APPOINTMENT_FRAGMENT,
  APPOINTMENT_ORDER_FRAGMENT,
  ENTERPRISE_FRAGMENT,
} from './fragments';

export const SIGN_IN = gql`
  mutation SIGN_IN($data: SignInInput!) {
    signIn(data: $data) {
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const SIGN_OUT = gql`
  mutation SIGN_OUT {
    signOut {
      success
    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      success
      err
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation CHANGE_PASSWORD($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      success
      err
    }
  }
`;

export const CREATE_USER = gql`
  mutation CREATE_USER($data: CreateUser!) {
    createUser(data: $data) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER(
    $record: UpdateOneUserInput!
    $filter: FilterUpdateOneUserInput
  ) {
    updateUser(record: $record, filter: $filter) {
      recordId
      record {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const SIGN_S3 = gql`
  mutation SIGN_S3($data: SignS3Input!) {
    signS3(data: $data) {
      signedRequest
      url
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation CREATE_CATEGORY($data: CreateCategoryInput!) {
    createCategory(data: $data) {
      _id
      name
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UPDATE_CATEGORY($data: updateCategoryInput!) {
    updateCategory(data: $data) {
      _id
      active
      name
    }
  }
`;

export const CREATE_TAX = gql`
  mutation CREATE_TAX($record: CreateOneTaxInput!) {
    createTax(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_TAX = gql`
  mutation UPDATE_TAX(
    $record: UpdateOneTaxInput!
    $filter: FilterUpdateOneTaxInput!
  ) {
    updateTax(record: $record, filter: $filter) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_CURRENCY = gql`
  mutation UPDATE_CURRENCY(
    $record: UpdateOneCurrencyInput!
    $filter: FilterUpdateOneCurrencyInput
  ) {
    updateCurrency(record: $record, filter: $filter) {
      record {
        ...CurrencyFragment
      }
    }
  }
  ${CURRENCY_FRAGMENT}
`;

export const CREATE_CURRENCY = gql`
  mutation CREATE_CURRENCY($record: CreateOneCurrencyInput!) {
    createCurrency(record: $record) {
      record {
        ...CurrencyFragment
      }
    }
  }
  ${CURRENCY_FRAGMENT}
`;

export const CREATE_PAYMENT_METHOD = gql`
  mutation CREATE_PAYMENT_METHOD($record: CreateOnePaymentMethodInput!) {
    createPaymentMethod(record: $record) {
      record {
        ...PaymentMethodFragment
      }
    }
  }
  ${PAYMENT_METHOD_FRAGMENT}
`;

export const UPDATE_PAYMENT_METHOD = gql`
  mutation UPDATE_PAYMENT_METHOD(
    $record: UpdateOnePaymentMethodInput!
    $filter: FilterUpdateOnePaymentMethodInput!
  ) {
    updatePaymentMethod(record: $record, filter: $filter) {
      record {
        ...PaymentMethodFragment
      }
    }
  }
  ${PAYMENT_METHOD_FRAGMENT}
`;

export const CREATE_FIRM = gql`
  mutation CREATE_FIRM($record: CreateOneFirmInput!) {
    createFirm(record: $record) {
      recordId
    }
  }
`;

export const UPDATE_FIRM = gql`
  mutation UPDATE_FIRM(
    $record: UpdateOneFirmInput!
    $filter: FilterUpdateOneFirmInput
  ) {
    updateFirm(record: $record, filter: $filter) {
      record {
        ...FirmFragment
      }
    }
  }
  ${FIRM_FRAGMENT}
`;

export const CREATE_COUNTRY = gql`
  mutation CREATE_COUNTRY($record: CreateOneCountryInput!) {
    createCountry(record: $record) {
      recordId
    }
  }
`;

export const UPDATE_COUNTRY = gql`
  mutation UPDATE_COUNTRY(
    $record: UpdateOneCountryInput!
    $filter: FilterUpdateOneCountryInput
  ) {
    updateCountry(record: $record, filter: $filter) {
      recordId
    }
  }
`;

export const CREATE_LAWYER = gql`
  mutation CREATE_LAWYER($data: CreateLawyerInput!) {
    createLawyer(data: $data) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_LAWYER = gql`
  mutation UPDATE_LAWYER($data: UpdateLawyerInput!) {
    customUpdateLawyer(data: $data) {
      ...LawyerFragment
    }
  }
  ${LAWYER_FRAGMENT}
`;

export const CREATE_CLIENT = gql`
  mutation CREATE_CLIENT($data: CreateClientInput!) {
    createClient(data: $data) {
      ...ClientFragment
    }
  }
  ${CLIENT_FRAGMENT}
`;
export const CREATE_CLIENT_USER = gql`
  mutation CREATE_CLIENT_USER($data: CreateClientUserInput!) {
    createClientUser(data: $data) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_CLIENT = gql`
  mutation UPDATE_CLIENT($data: UpdateClientInput!) {
    updateClient(data: $data) {
      ...ClientFragment
    }
  }
  ${CLIENT_FRAGMENT}
`;

export const UPDATE_CLIENT_USER = gql`
  mutation UPDATE_CLIENT_USER($data: UpdateClientUserInput!) {
    updateClientUser(data: $data) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_PERMISSION = gql`
  mutation UPDATE_PERMISSION($data: UpdatePermissionInput!) {
    updatePermission(data: $data) {
      permissionId
      name
      active
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation UPDATE_DOCUMENT($data: UpdateDocumentInput!) {
    updateDocument(data: $data) {
      ...DocumentFragment
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const CREATE_DOCUMENT = gql`
  mutation CREATE_DOCUMENT($data: CreateDocumentInput!) {
    createDocument(data: $data) {
      ...DocumentFragment
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const UPDATE_DOCUMENT_TEMPLATE = gql`
  mutation UPDATE_DOCUMENT_TEMPLATE($data: UpdateDocumentTemplateInput!) {
    updateDocumentTemplate(data: $data) {
      ...DocumentFragment
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const CREATE_VARIABLE = gql`
  mutation CREATE_VARIABLE($data: CreateVariableInput!) {
    createVariable(data: $data) {
      ...VariableFragment
    }
  }
  ${VARIABLE_FRAGMENT}
`;
export const UPDATE_VARIABLE = gql`
  mutation UPDATE_VARIABLE($data: UpdateVariableInput!) {
    updateVariable(data: $data) {
      ...VariableFragment
    }
  }
  ${VARIABLE_FRAGMENT}
`;

export const CREATE_QUESTIONNAIRE = gql`
  mutation CREATE_QUESTIONNAIRE($record: CreateOneQuestionnaireInput!) {
    createQuestionnaire(record: $record) {
      recordId
      record {
        ...QuestionnaireFragment
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;

export const UPDATE_QUESTIONNAIRE = gql`
  mutation UPDATE_QUESTIONNAIRE(
    $record: UpdateOneQuestionnaireInput!
    $filter: FilterUpdateOneQuestionnaireInput
  ) {
    updateQuestionnaire(record: $record, filter: $filter) {
      record {
        ...QuestionnaireFragment
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;

export const CREATE_PROMOTION = gql`
  mutation CREATE_PROMOTION($record: CreateOnePromotionInput!) {
    createPromotion(record: $record) {
      recordId
      record {
        ...PromotionFragment
      }
    }
  }
  ${PROMOTION_FRAGMENT}
`;

export const UPDATE_PROMOTION = gql`
  mutation UPDATE_PROMOTION(
    $record: UpdateOnePromotionInput!
    $filter: FilterUpdateOnePromotionInput
  ) {
    updatePromotion(record: $record, filter: $filter) {
      record {
        ...PromotionFragment
      }
    }
  }
  ${PROMOTION_FRAGMENT}
`;

export const CREATE_GUIDE = gql`
  mutation CREATE_GUIDE($record: CreateOneGuideInput!) {
    createGuide(record: $record) {
      recordId
      record {
        ...GuideFragment
      }
    }
  }
  ${GUIDE_FRAGMENT}
`;

export const UPDATE_GUIDE = gql`
  mutation UPDATE_GUIDE(
    $record: UpdateOneGuideInput!
    $filter: FilterUpdateOneGuideInput
  ) {
    updateGuide(record: $record, filter: $filter) {
      recordId
      record {
        ...GuideFragment
      }
    }
  }
  ${GUIDE_FRAGMENT}
`;

export const UPDATE_PAYMENT = gql`
  mutation UPDATE_PAYMENT(
    $record: UpdateOnePaymentInput!
    $filter: FilterUpdateOnePaymentInput
  ) {
    updatePayment(record: $record, filter: $filter) {
      record {
        ...PaymentFragment
      }
    }
  }
  ${PAYMENT_FRAGMENT}
`;

export const CREATE_APPOINTMENT = gql`
  mutation CREATE_APPOINTMENT($record: CreateOneAppointmentInput!) {
    createAppointment(record: $record) {
      recordId
      record {
        ...AppointmentFragment
      }
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const UPDATE_APPOINTMENT = gql`
  mutation UPDATE_APPOINTMENT(
    $record: UpdateOneAppointmentInput!
    $filter: FilterUpdateOneAppointmentInput
  ) {
    updateAppointment(record: $record, filter: $filter) {
      record {
        ...AppointmentFragment
      }
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const CREATE_APPOINTMENT_ORDER = gql`
  mutation CREATE_APPOINTMENT_ORDER($record: CreateOneAppointmentOrderInput!) {
    createAppointmentOrder(record: $record) {
      recordId
      record {
        ...AppointmentOrderFragment
      }
    }
  }
  ${APPOINTMENT_ORDER_FRAGMENT}
`;

export const UPDATE_APPOINTMENT_ORDER = gql`
  mutation UPDATE_APPOINTMENT_ORDER(
    $record: UpdateOneAppointmentOrderInput!
    $filter: FilterUpdateOneAppointmentOrderInput
  ) {
    updateAppointmentOrder(record: $record, filter: $filter) {
      record {
        ...AppointmentOrderFragment
      }
    }
  }
  ${APPOINTMENT_ORDER_FRAGMENT}
`;

export const CREATE_ENTERPRISE = gql`
  mutation CREATE_ENTERPRISE($record: CreateOneEnterpriseInput!) {
    createEnterprise(record: $record) {
      recordId
      record {
        ...EnterpriseFragment
      }
    }
  }
  ${ENTERPRISE_FRAGMENT}
`;

export const UPDATE_ENTERPRISE = gql`
  mutation UPDATE_ENTERPRISE(
    $record: UpdateOneEnterpriseInput!
    $filter: FilterUpdateOneEnterpriseInput
  ) {
    updateEnterprise(record: $record, filter: $filter) {
      record {
        ...EnterpriseFragment
      }
    }
  }
  ${ENTERPRISE_FRAGMENT}
`;

export const SEND_CORPORATIVE_APPROVAL = gql`
  mutation SEND_CORPORATIVE_APPROVAL(
    $data: SendCorporativeApprovalEmailInput!
  ) {
    corporativeApprovalEmail(data: $data) {
      success
    }
  }
`;

export const CUSTOM_UPDATE_ENTERPRISE = gql`
  mutation CUSTOM_UPDATE_ENTERPRISE($data: UpdateEnterprise!) {
    customUpdateEnterprise(data: $data) {
      ...EnterpriseFragment
    }
  }
  ${ENTERPRISE_FRAGMENT}
`;
