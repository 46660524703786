import React from 'react';
import Modal from './Modal';
import Button from './Button';
import CloseIcon from '../icons/CloseIcon';

interface ConfirmationModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  text?: string;
  onClick: (e?: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
}

function ConfirmationModal({
  isOpen,
  setOpen,
  text,
  onClick,
}: ConfirmationModalProps) {
  return (
    <Modal isOpen={isOpen} setOpen={setOpen} title="">
      <div className="w-full flex justify-between mb-2">
        <h1 className="font-semibold text-secondary-500">¿Desea continuar?</h1>
        <button
          type="button"
          className="ml-auto flex h-6 w-6"
          onClick={(e) => {
            e.preventDefault();
            setOpen(false);
          }}
        >
          <CloseIcon className="w-3 h-3 m-auto text-neutral-500" />
          <span className="sr-only">Cerrar</span>
        </button>
      </div>
      <div className="w-full">
        <p className="text-neutral-500">{text}</p>
      </div>

      <div className="flex  gap-2 mt-6 w-full">
        <Button
          outline
          color="neutral"
          type="button"
          className="w-1/2 "
          onClick={(e) => {
            e.preventDefault();
            setOpen(false);
          }}
        >
          Cancelar
        </Button>
        <Button
          color=""
          type="button"
          className="w-1/2"
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
            setOpen(false);
          }}
        >
          Continuar
        </Button>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
