import React from 'react';

function ClientsIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.15837 2.66663C9.15837 4.04996 8.05004 5.16663 6.66671 5.16663C5.28337 5.16663 4.16671 4.04996 4.16671 2.66663C4.16671 1.28329 5.28337 0.166626 6.66671 0.166626C8.05004 0.166626 9.15837 1.28329 9.15837 2.66663ZM15.825 2.66663C15.825 4.04996 14.7167 5.16663 13.3334 5.16663C11.95 5.16663 10.8334 4.04996 10.8334 2.66663C10.8334 1.28329 11.95 0.166626 13.3334 0.166626C14.7167 0.166626 15.825 1.28329 15.825 2.66663ZM6.66671 6.83329C4.72504 6.83329 0.833374 7.80829 0.833374 9.74996V11C0.833374 11.4583 1.20837 11.8333 1.66671 11.8333H11.6667C12.125 11.8333 12.5 11.4583 12.5 11V9.74996C12.5 7.80829 8.60837 6.83329 6.66671 6.83329ZM12.525 6.87496C12.8167 6.84996 13.0917 6.83329 13.3334 6.83329C15.275 6.83329 19.1667 7.80829 19.1667 9.74996V11C19.1667 11.4583 18.7917 11.8333 18.3334 11.8333H14.0167C14.1084 11.575 14.1667 11.2916 14.1667 11V9.74996C14.1667 8.52496 13.5084 7.59996 12.5584 6.90829C12.5559 6.90577 12.5533 6.90248 12.5506 6.89889C12.5442 6.89063 12.5367 6.88077 12.525 6.87496Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ClientsIcon;
