/* eslint-disable react/style-prop-object */
import React from 'react';

function Questionnaire({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6666 0.666656H2.33329C1.41663 0.666656 0.666626 1.41666 0.666626 2.33332V15.6667C0.666626 16.5833 1.41663 17.3333 2.33329 17.3333H15.6666C16.5833 17.3333 17.3333 16.5833 17.3333 15.6667V2.33332C17.3333 1.41666 16.5833 0.666656 15.6666 0.666656ZM8.99996 5.64999L7.27496 10.25H10.725L8.99996 5.64999ZM11.3416 11.9167L12.0916 13.9C12.2166 14.2083 12.5166 14.4167 12.85 14.4167C13.4166 14.4167 13.8083 13.8417 13.6 13.3167L10.0583 4.30832C9.88329 3.86666 9.46663 3.58332 8.99996 3.58332C8.53329 3.58332 8.11663 3.86666 7.93329 4.30832L4.39163 13.3167C4.18329 13.8417 4.57496 14.4167 5.14163 14.4167C5.48329 14.4167 5.78329 14.2083 5.89996 13.8917L6.64163 11.9167H11.3416Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Questionnaire;
