/* eslint-disable react/style-prop-object */
import React from 'react';

function Guides({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3C4 2.45 4.45 2 5 2H21C21.55 2 22 1.55 22 1C22 0.45 21.55 0 21 0H4C2.9 0 2 0.9 2 2V13H1.5C0.67 13 0 13.67 0 14.5C0 15.33 0.67 16 1.5 16H14V13H4V3ZM23 4H17C16.45 4 16 4.45 16 5V15C16 15.55 16.45 16 17 16H23C23.55 16 24 15.55 24 15V5C24 4.45 23.55 4 23 4ZM18 13H22V6H18V13Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Guides;
