/* eslint-disable react/style-prop-object */
import React from 'react';

function Currency({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        d="M5.24967 7.08333C3.35801 6.59167 2.74967 6.08333 2.74967 5.29167C2.74967 4.38333 3.59134 3.75 4.99967 3.75C6.18301 3.75 6.77467 4.2 6.99134 4.91667C7.09134 5.25 7.36634 5.5 7.71634 5.5H7.96634C8.51634 5.5 8.90801 4.95833 8.71634 4.44167C8.36634 3.45833 7.54967 2.64167 6.24967 2.325V1.75C6.24967 1.05833 5.69134 0.5 4.99967 0.5C4.30801 0.5 3.74967 1.05833 3.74967 1.75V2.3C2.13301 2.65 0.833008 3.7 0.833008 5.30833C0.833008 7.23333 2.42467 8.19167 4.74967 8.75C6.83301 9.25 7.24967 9.98333 7.24967 10.7583C7.24967 11.3333 6.84134 12.25 4.99967 12.25C3.62467 12.25 2.91634 11.7583 2.64134 11.0583C2.51634 10.7333 2.23301 10.5 1.89134 10.5H1.65801C1.09967 10.5 0.708008 11.0667 0.916341 11.5833C1.39134 12.7417 2.49967 13.425 3.74967 13.6917V14.25C3.74967 14.9417 4.30801 15.5 4.99967 15.5C5.69134 15.5 6.24967 14.9417 6.24967 14.25V13.7083C7.87467 13.4 9.16634 12.4583 9.16634 10.75C9.16634 8.38333 7.14134 7.575 5.24967 7.08333Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Currency;
