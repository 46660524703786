import { CalendarIcon, CogIcon, RssIcon } from '@heroicons/react/24/outline';
import ClientsIcon from '../components/icons/ClientsIcon';
import DashboardIcon from '../components/icons/DashboardIcon';
import QuestionnaireIcon from '../components/icons/Questionnaire';
import PrivilegesIcon from '../components/icons/PrivilegesIcon';
import DocumentsIcon from '../components/icons/DocumentsIcon';
import FirmIcon from '../components/icons/FirmIcon';
import CountryIcon from '../components/icons/CountryIcon';
import LawyerIcon from '../components/icons/LawyerIcon';
import PaymentIcon from '../components/icons/PaymentIcon';
import CountriesIcon from '../components/icons/CountriesIcon';
import CurrencyIcon from '../components/icons/CurrencyIcon';
import TaxesIcon from '../components/icons/TaxesIcon';
import PaymentMethodIcon from '../components/icons/PaymenMethodIcon';
import CategoriesIcon from '../components/icons/CategoriesIcon';
import PromotionsIcon from '../components/icons/PromotionsIcon';
import GuidesIcon from '../components/icons/GuidesIcon';
import OrdersIcon from '../components/icons/OrdersIcon';
import VariablesIcon from '../components/icons/InformationIcon';
import ServerIcon from '../components/icons/ServerIcon';

const getIcon = (icon: string, className) => {
  switch (icon) {
    case 'clients':
      return <ClientsIcon className={`${className}`} />;
    case 'orders':
      return <OrdersIcon className={`${className}`} />;
    case 'dashboard':
      return <DashboardIcon className={` ${className}`} />;
    case 'questionnaire':
      return <QuestionnaireIcon className={` ${className}`} />;
    case 'questionnaires':
      return <QuestionnaireIcon className={` ${className}`} />;
    case 'documents':
      return <DocumentsIcon className={` ${className}`} />;
    case 'privileges':
      return <PrivilegesIcon className={` ${className}`} />;
    case 'lawyers':
      return <LawyerIcon className={` ${className}`} />;
    case 'payments':
      return <PaymentIcon className={` ${className}`} />;
    case 'users':
      return <PrivilegesIcon className={` ${className}`} />;
    case 'user':
      return <PrivilegesIcon className={` ${className}`} />;
    case 'firm':
      return <FirmIcon className={` ${className}`} />;
    case 'firms':
      return <FirmIcon className={` ${className}`} />;
    case 'country':
      return <CountryIcon className={` ${className}`} />;
    case 'countries':
      return <CountriesIcon className={` ${className}`} />;
    case 'currency':
      return <CurrencyIcon className={` ${className}`} />;
    case 'currencies':
      return <CurrencyIcon className={` ${className}`} />;
    case 'taxes':
      return <TaxesIcon className={` ${className}`} />;
    case 'variables':
      return <VariablesIcon className={` ${className}`} />;
    case 'paymentMethod':
      return <PaymentMethodIcon className={` ${className}`} />;
    case 'payment-methods':
      return <PaymentMethodIcon className={` ${className}`} />;
    case 'categories':
      return <CategoriesIcon className={` ${className}`} />;
    case 'promotions':
      return <PromotionsIcon className={` ${className}`} />;
    case 'guides':
      return <GuidesIcon className={` ${className}`} />;
    case 'appointments':
      return <CalendarIcon className={` ${className}`} />;
    case 'enterprises':
      return <TaxesIcon className={` ${className}`} />;
    case 'enterprises-summary':
      return <ServerIcon className={` ${className}`} />;

    default:
      break;
  }
};

export default getIcon;
