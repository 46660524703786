/* eslint-disable no-nested-ternary */
import React from 'react';
import { Combobox, Transition } from '@headlessui/react';
import CheckIcon from '../icons/CheckIcon';
import SelectorIcon from '../icons/AddIcon';
import LessIcon from '../icons/LessIcon';
import Button from './Button';

export type IOption = {
  _id: string;
  name: string;
  disabled?: boolean;
};

interface MultipleSelectProps {
  onChangeSelect: (selectedoptions: any, name: string) => void;
  options: IOption[];
  defaultSelect?: IOption[] | [];
  label: string;
  resetSelection?: boolean;
  setResetSelection?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  actionName?: string;
  description: string;
  isReducer?: boolean;
  setState?: React.Dispatch<React.SetStateAction<any>>;
}

export default function MultipleSelect({
  label,
  onChangeSelect,
  options,
  defaultSelect = [],
  className = '',
  actionName = '',
  description,
  resetSelection = false,
  setResetSelection,
  ...props
}: MultipleSelectProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState<IOption[]>([]);
  const [defaultOptions, setDefaultOptions] = React.useState<IOption[]>([]);

  function isSelectedOptions(value: IOption) {
    return selectedOptions.find((el) => el._id === value._id);
  }

  function handleSelect(value: IOption) {
    if (!isSelectedOptions(value)) {
      const selectedOptionsUpdated = [
        ...selectedOptions,
        options.find((el) => el === value),
      ];
      setSelectedOptions(selectedOptionsUpdated as unknown as IOption[]);
    } else {
      handleDeselect(value);
    }
    setIsOpen(true);
  }

  function handleDeselect(value: IOption) {
    const selectedOptionsUpdated = selectedOptions.filter(
      (el) => el._id !== value._id
    );
    setSelectedOptions(selectedOptionsUpdated);
    setIsOpen(true);
  }

  React.useEffect(() => {
    if (
      defaultSelect?.length > 0 &&
      selectedOptions.length === 0 &&
      defaultOptions.length === 0
    ) {
      setSelectedOptions(defaultSelect);
      setDefaultOptions(defaultSelect);
    }
  }, [defaultSelect]);

  React.useEffect(() => {
    if (resetSelection && setResetSelection) {
      setSelectedOptions([]);
      setResetSelection(false);
    }
  }, [resetSelection]);

  React.useEffect(() => {
    onChangeSelect(selectedOptions, actionName);
  }, [selectedOptions]);

  return (
    <div className="z-100 items-center justify-center h-full flex w-full flex-wrap">
      <div className="w-full h-full flex flex-wrap ">
        <span className="text-neutral-800 text-sm font-normal mb-2 ">
          {label}
        </span>
        <Combobox
          className=" flex w-full flex-wrap justify-around items-center border-blue-400"
          as="div"
          value={selectedOptions}
          onChange={(e: any) => {
            handleSelect(e);
          }}
        >
          {() => (
            <div className="relative flex w-full flex-wrap items-center">
              <Combobox.Button
                className=" cursor-pointer relative w-full h-full rounded  border-green-500 bg-white text-left transition ease-in-out duration-150 flex justify-around items-center "
                onClick={() => setIsOpen(!isOpen)}
              >
                {/* Widu */}
                <div
                  className={`p-2 mr-1 w-full h-fit flex flex-wrap border border-neutral-300 rounded-md gap-1  ${
                    defaultSelect?.length === 0 ? ' cursor-default ' : ''
                  }`}
                >
                  {selectedOptions?.length === 0 ? (
                    <p className="text-neutral-400">{description}</p>
                  ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                      {selectedOptions.map((c) => (
                        <Button
                          type="button"
                          buttonType="blueCategory"
                          key={c?._id}
                          onClick={(e) => {
                            e.preventDefault();
                            handleSelect(c);
                          }}
                        >
                          {c?.name}
                        </Button>
                      ))}
                    </>
                  )}
                </div>
                {/* Add button */}
                <div className="relative inset-y-0 right-0 flex items-center pointer-events-none  cursor-pointer z-50">
                  {isOpen ? (
                    <LessIcon
                      className="h-4 w-4 ml-1 text-secondary-500 cursor-pointer"
                      aria-hidden="true"
                    />
                  ) : (
                    <SelectorIcon
                      className="h-4 w-4 ml-1 text-secondary-500 cursor-pointer"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </Combobox.Button>
              {/* </span> */}

              <Transition
                unmount={false}
                show={isOpen}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="w-full"
              >
                <Combobox.Options
                  static
                  className="absolute w-full max-h-60 rounded py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none  sm:leading-5  mt-1 bg-white shadow-lg z-50"
                  onMouseLeave={(e) => {
                    e.preventDefault();
                    setIsOpen(false);
                  }}
                >
                  {options?.map((option) => {
                    const selected = isSelectedOptions(option);
                    return (
                      <Combobox.Option
                        key={option?._id}
                        value={option}
                        disabled={option?.disabled ?? false}
                        className={({ active }) =>
                          `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                            active
                              ? 'bg-[#3375B4] text-neutral-200'
                              : option?.disabled
                              ? 'text-neutral-100 cursor-not-allowed'
                              : 'text-gray-900'
                          }`
                        }
                      >
                        {({ active }) => (
                          <>
                            <span
                              className={`block truncate font-light tracking-wider text-sm ${
                                selected ? 'font-medium' : ''
                              }`}
                            >
                              {option?.name}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? 'text-white' : 'text-primary-400'
                                }`}
                              >
                                <CheckIcon
                                  className="h-4 w-4 text-green-300"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    );
                  })}
                </Combobox.Options>
              </Transition>
            </div>
          )}
        </Combobox>
      </div>
    </div>
  );
}
