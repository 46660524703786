/* eslint-disable react/style-prop-object */
import React from 'react';

function Questionnaire({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        d="M0.452117 9.11932C0.289524 8.95673 0.28938 8.69315 0.451796 8.53038L3.97448 5.00001L0.451796 1.46964C0.28938 1.30687 0.289524 1.0433 0.452117 0.880704L1.03818 0.294638C1.2009 0.13192 1.46472 0.13192 1.62744 0.294639L6.03818 4.70538C6.2009 4.8681 6.2009 5.13192 6.03818 5.29464L1.62744 9.70538C1.46472 9.8681 1.2009 9.8681 1.03818 9.70538L0.452117 9.11932Z"
        fill="#D4F1F8"
      />
    </svg>
  );
}

export default Questionnaire;
