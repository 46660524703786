import React from 'react';

function ElSalvadorIcon({
  className,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <g clipPath="url(#clip0_4_7145)">
        <path
          d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
          fill="#F0F0F0"
        />
        <path
          d="M256 0C154.506 0 66.8103 59.065 25.4023 144.696H486.597C445.19 59.065 357.493 0 256 0Z"
          fill="#0052B4"
        />
        <path
          d="M256 512C357.493 512 445.19 452.935 486.598 367.304H25.4023C66.8103 452.935 154.506 512 256 512Z"
          fill="#0052B4"
        />
        <path
          d="M204.59 267.13L256 178.087L307.41 267.13H204.59Z"
          fill="#FFDA44"
        />
        <path
          d="M322.783 296.531L256 329.923L189.217 296.531V252.01H322.783V296.531Z"
          fill="#6DA544"
        />
        <path
          d="M318.963 181.907L295.352 205.518C305.423 215.589 311.653 229.502 311.653 244.87C311.653 275.606 286.736 300.522 256.001 300.522C225.266 300.522 200.349 275.605 200.349 244.87C200.349 229.502 206.579 215.589 216.65 205.518L193.039 181.907C176.924 198.019 166.958 220.28 166.958 244.87C166.958 294.048 206.824 333.913 256.001 333.913C305.178 333.913 345.044 294.047 345.044 244.87C345.043 220.28 335.077 198.019 318.963 181.907Z"
          fill="#FFDA44"
        />
      </g>
      <defs>
        <clipPath id="clip0_4_7145">
          <rect width="512" height="512" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ElSalvadorIcon;
