import React from 'react';

interface CardProps {
  children?: React.ReactNode;
  title?: string;
  className?: string;
  hasButton?: boolean;
  hasTwoButtons?: boolean;
  onClick1?: () => void;
  onClick2?: () => void;
  button1Label?: string;
  button2Label?: string;
}

function Card({
  className,
  children,
  title = '',
  hasButton = false,
  hasTwoButtons = false,
  onClick1,
  onClick2,
  button1Label,
  button2Label,
}: CardProps) {
  return (
    <section
      className={`flex flex-col flex-wrap w-full bg-background-100 rounded-md shadow-gray mx-auto ${className}`}
    >
      <div className="w-full py-3 border-b border-gray-200 p-6">
        <h2 className="text-secondary-500 text-md uppercase font-medium">
          {title}
        </h2>
      </div>
      <div className="w-full p-6">{children}</div>
      {hasButton ? (
        <div className="w-full bg-secondary-500 bg-opacity-10 h-10 rounded-b-md items-center justify-end flex">
          {hasTwoButtons ? (
            <div className="flex gap-2">
              <button
                className="text-secondary-600 text-sm hover:text-secondary-400"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  onClick1();
                }}
              >
                {button1Label}
              </button>
              <p className="text-secondary-500">|</p>
              <button
                className="mr-6 text-secondary-600 text-sm hover:text-secondary-400"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  onClick2();
                }}
              >
                {button2Label}
              </button>
            </div>
          ) : (
            <button
              className="mr-6 text-secondary-600 text-sm hover:text-secondary-400"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                onClick1();
              }}
            >
              {button1Label}
            </button>
          )}
        </div>
      ) : null}
    </section>
  );
}

export default Card;
