/* eslint-disable react/style-prop-object */
import React from 'react';

function Firm({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6665 3.83333H12.3332C12.3332 1.99167 10.8415 0.5 8.99984 0.5C7.15817 0.5 5.6665 1.99167 5.6665 3.83333H2.33317C1.4165 3.83333 0.666504 4.58333 0.666504 5.5V8C0.666504 8.925 1.40817 9.66667 2.33317 9.66667H7.33317V8.83333C7.33317 8.375 7.70817 8 8.1665 8H9.83317C10.2915 8 10.6665 8.375 10.6665 8.83333V9.66667H15.6665C16.5832 9.66667 17.3332 8.91667 17.3332 8V5.5C17.3332 4.58333 16.5832 3.83333 15.6665 3.83333ZM9.83317 11.3333H8.1665C7.70817 11.3333 7.33317 10.9583 7.33317 10.5H1.50817V13.8333C1.50817 14.75 2.25817 15.5 3.17484 15.5H14.8332C15.7498 15.5 16.4998 14.75 16.4998 13.8333V10.5H10.6665C10.6665 10.9583 10.2915 11.3333 9.83317 11.3333ZM8.99984 2.16667C8.08317 2.16667 7.33317 2.91667 7.33317 3.83333H10.6665C10.6665 2.91667 9.9165 2.16667 8.99984 2.16667Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Firm;
