/* eslint-disable react/style-prop-object */
import React from 'react';

function LawyerIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(props as any)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4165 3.08329C9.4165 4.69163 8.10817 5.99996 6.49984 5.99996C4.8915 5.99996 3.58317 4.69163 3.58317 3.08329C3.58317 1.47496 4.8915 0.166626 6.49984 0.166626C8.10817 0.166626 9.4165 1.47496 9.4165 3.08329ZM7.74984 3.08329C7.74984 2.39163 7.1915 1.83329 6.49984 1.83329C5.80817 1.83329 5.24984 2.39163 5.24984 3.08329C5.24984 3.77496 5.80817 4.33329 6.49984 4.33329C7.1915 4.33329 7.74984 3.77496 7.74984 3.08329ZM6.49984 7.45829C4.54984 7.45829 0.666504 8.43329 0.666504 10.375V11C0.666504 11.4583 1.0415 11.8333 1.49984 11.8333H11.4998C11.9582 11.8333 12.3332 11.4583 12.3332 11V10.375C12.3332 8.43329 8.44984 7.45829 6.49984 7.45829ZM6.49984 9.12496C5.00817 9.12496 3.3165 9.68329 2.6165 10.1666H10.3832C9.68317 9.68329 7.9915 9.12496 6.49984 9.12496ZM12.3665 7.50829C13.3332 8.20829 13.9998 9.14163 13.9998 10.375V11.8333H16.4998C16.9582 11.8333 17.3332 11.4583 17.3332 11V10.375C17.3332 8.69163 14.4165 7.73329 12.3665 7.50829ZM14.4165 3.08329C14.4165 4.69163 13.1082 5.99996 11.4998 5.99996C11.0498 5.99996 10.6332 5.89163 10.2498 5.70829C10.7748 4.96663 11.0832 4.05829 11.0832 3.08329C11.0832 2.10829 10.7748 1.19996 10.2498 0.458293C10.6332 0.274959 11.0498 0.166626 11.4998 0.166626C13.1082 0.166626 14.4165 1.47496 14.4165 3.08329Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default LawyerIcon;
